<template>
    <div id="login" class="login-content">
        <div class="login-title">
            <p>管理员登录</p>
        </div>
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-button type="primary" @click="sign" class="login-submit">登录</el-button>
        </el-form>
    </div>
</template>


<script type="text/javascript" >
    import ("@/static/js/ribbon.js");
    import md5 from 'js-md5';
    export default {
        name: 'Login',
        data() {
            return {
                form:{
                    username: '',
                    password: '',
                },
                rules: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
                    ],
                }
            }
        },
        methods: {
            sign() {
                let that = this;
                let username = that.form.username;
                let password = that.form.password;
                if (!username) {
                    this.$message({
                        message: '用户名不能为空',
                        type: 'error'
                    });
                    return
                }
                if (username.length<2||username.length>10){
                    this.$message({
                        message: '用户名长度为2-10',
                        type: 'error'
                    });
                    return
                }
                if (!password) {
                    this.$message({
                        message: '密码不能为空',
                        type: 'error'
                    });
                }
                if (password.length<6||username.length>16){
                    this.$message({
                        message: '密码长度为6-16',
                        type: 'error'
                    });
                    return
                }

                const formData = new FormData();
                formData.append('username', md5(username));
                formData.append('pwd', md5(password));
                that.axios.post(that.common.httpUrl+'/v1/login',formData).then((res) => {
                    if (res.data.code==that.common.httpCode.succ){
                        this.$message({
                            message: '登录成功！',
                            type: 'success'
                        });
                        that.common.updateApiToken(res.data.data.token);
                        that.common.updateUserInfo(`{"uid":`+res.data.data.uid+`,"avatar":"`+res.data.data.avatar+`","nickname":"`+res.data.data.nickname+`"}`);
                        this.$router.push("/")
                    }else if (res.data.code==that.common.httpCode.err404){
                        this.$message({
                            message: '账户名或密码错误!',
                            type: 'error'
                        });
                    }else {
                        this.$message({
                            message: res.data.data,
                            type: 'error'
                        });
                    }
                }).catch((err) => {
                    this.$message({
                        message: "请求失败！",
                        type: 'error'
                    });
                });
            }
        }
    }
</script>

<style>
    .login-content {
        width: 500px;
        height: 300px;
        box-sizing: border-box;
        padding: 0 50px;
        border-radius: 5px;
        box-shadow: 0px 2px 12px 0px rgba(105, 105, 105, 0.07);
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: mymove 1s ease-in-out  alternate;
        overflow: hidden;
        transition: 1.5s;
    }
    .login-submit{
        width: 320px;
        height: 40px;
        margin-left: 80px;
    }
    .login-title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 30px;
        font-weight: bold;
        color: #606266;
    }

</style>
